import React from 'react'
import img from '../../assets/img/LOGO MAHOPTION.webp'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import team from '../../assets/img/team.jpeg'


const links = [
    {nom:'Home', path:'/'},
    {nom:'TV', path:'tv'},
    {nom:'Calendrier', path:'calendar'},
    {nom:'revue de presse', path:'revue-presse'},
    {nom:"s'inscrire", path:'contact'},
]

const Header = () => {
    const [bg, setBg] = useState(false)

    window.addEventListener("scroll",()=>{
        if( window.scrollY > 100 ){
            setBg(true)
        }else{
            setBg(false)
        }
    })
    const [show, setShow] = useState(false)
    return (
        <header id='head' className={'fixed-top' + (bg?' pt-2 bg-black pb-2 pb-md-0':'')}>
            <div className='container d-md-flex'>
                <div className='row justify-content-lg-center justify-content-between align-items-center'>
                    <NavLink className={"col-md-auto col"} onClick={()=>setShow(false)} to='/'>
                        <img src={img} alt='logo Mahoption LTD' className='navbar-brand img-brand py-1' />
                        <span className='text-white d-md-inline ps-md-3 d-block'>"Votre satisfaction n'est pas une option !"</span>
                    </NavLink>
                    <i role='button' onClick={()=>setShow(!show)} id="menu-button" className={'fa-bars fa d-block d-md-none text-white fa-lg pt-3 col-auto'}></i>
                    <nav className={'navbar col-auto d-block d-md-flex text-white pb-0 ' + (show ? 'show-nav shadow bg-black':'')} >
                        {
                            links.map((elt,index)=>(
                                <NavLink onClick={()=>{setShow(false); window.scrollTo({ top: 0, left: 0})}}  activeclassname='active' className={'nav-link pb-3 d-inline-block position-relative nav-link-ltr aer-link text-capitalize mx-3 mb-0 fw-bold ' + (bg?'':'text-black text-opacity-75')} to={elt.path} key={index} > {elt.nom} </NavLink>
                            ))
                        }
                        <a  className={'nav-link pb-3 d-inline-block position-relative nav-link-ltr aer-link text-capitalize mx-3 mb-0 fw-bold ' + (bg?'':'text-black text-opacity-75')} href='https://ear-gt.com' target='_BLANK' rel='noreferrer' > EAR Rallye GT </a>
                        <div className={'switch-language nav-link pb-3 d-inline-block position-relative  mx-3 mb-0 fw-bold ms-md-0 ms-4'} ></div>
                        <div className='d-md-none'>
                            <img className='img-fluid' alt='team Mahoption' src={team} />
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Header