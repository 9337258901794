import React, { useState } from 'react'
import net from '../../assets/img/icons.png'

const Social = () => {
    const [social, setSocial] = useState(false)

    return (
        <>
            <div className='d-sm-flex d-none position-fixed social-logo left flex-column'>
                <div className='bg-instagram rounded-circle mb-3 py-md-2 py-1 shadow' title='instagram'>
                    <a target={'_blank'} rel='noreferrer' href='https://instagram.com/mahoption_'><i className='eventify-social fa-brands text-white fa-instagram pt-1'></i></a>
                </div>
                <div className='bg-linkedin rounded-circle mb-3 py-md-2 py-1 shadow d-flex justify-content-center align-items-center' title='linkedin'>
                    <a target={'_blank'} rel='noreferrer' href='https://www.linkedin.com/company/mahoption/'><i className='eventify-social fa-brands text-white fa-linkedin-in pt-1'></i></a>
                </div>
                <div className='bg-facebook rounded-circle mb-3 py-md-2 py-1 shadow d-flex justify-content-center align-items-center' title='facebook'>
                    <a target={'_blank'} rel='noreferrer' href='https://www.facebook.com/missmahop?mibextid=LQQJ4d'><i className='eventify-social fa-brands text-white fa-facebook-f pt-0 pt-md-1'></i></a>
                </div>
                <div className='bg-danger rounded-circle mb-3 py-md-2 py-1 shadow d-flex justify-content-center align-items-center' title='facebook'>
                    <a target={'_blank'} rel='noreferrer' href='https://youtube.com/@MAHOPTION'><i className='eventify-social fa-brands text-white fa-youtube px-2 pt-0 pt-md-1'></i></a>
                </div>
            </div>
            <div role='button' className='d-sm-none d-flex align-items-end social-logo-sm position-fixed'>
                <div className={(social?"row":"d-none" )+ " mb-4 me-3 bg-white shadow rounded px-2 px-1"} >
                    <div className='position-relative'>
                        <div className='px-2 py-1 rounded-circle close bg-white'>
                            <i onClick={()=>setSocial(!social)} className='fa fa-xmark fa-xl '></i>
                        </div>
                        <div className='bg-instagram rounded-circle mb-3 py-md-2 py-1 shadow' title='instagram'>
                            <a target={'_blank'} rel='noreferrer' href='https://instagram.com/mahoption_'><i className='eventify-social fa-brands text-white fa-instagram'></i></a>
                        </div>
                        <div className='bg-linkedin rounded-circle mb-3 py-md-2 py-1 shadow d-flex justify-content-center align-items-center' title='linkedin'>
                            <a target={'_blank'} rel='noreferrer' href='https://www.linkedin.com/company/mahoption/'><i className='eventify-social fa-brands text-white fa-linkedin-in'></i></a>
                        </div>
                        <div className='bg-facebook rounded-circle mb-3 py-md-2 py-1 shadow d-flex justify-content-center align-items-center' title='facebook'>
                            <a target={'_blank'} rel='noreferrer' href='https://www.facebook.com/missmahop?mibextid=LQQJ4d'><i className='eventify-social fa-brands text-white fa-facebook-f'></i></a>
                        </div>
                        <div className='bg-danger rounded-circle mb-3 py-md-2 py-1 shadow d-flex justify-content-center align-items-center' title='facebook'>
                            <a target={'_blank'} rel='noreferrer' href='https://youtube.com/@MAHOPTION'><i className='eventify-social fa-brands text-white fa-youtube'></i></a>
                        </div>
                    </div>
                </div>
                {/* <div onClick={()=>setSocial(!social)} className={(social?"d-none":"" )+' p-2 rounded-circle shadow me-4 mb-5 bg-white w-25'}>
                    <i className="" ><img src={net} className='img-fluid px-1 py-3' alt='Réseaux sociaux'/></i>
                </div> */}
                <div onClick={()=>setSocial(!social)} className={(social?"d-none":"" )+" row mb-5 bg-white shadow rounded-circle"}   style={{width: "90%"}}>
                    <div className='position-relative'>
                        <img src={net} className='img-fluid py-3 px-1' alt='Réseaux sociaux'/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Social