import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { URL_API_CALENDAR, URL_API_REVUE, URL_API_REVUE_CAT, URL_API_TV, URL_API_TV_CAT } from '../constant/url'

export const AppContexte = React.createContext()

export const GlobalApp  = ({children}) =>{


    // Revue
    const [catRevue, setCatRevue] = useState([])
    const [revue, setRevue] =  useState([])
    const [revueErr, setRevueErr] = useState(false)
    const [revueLoad, setRevueLoad] = useState(true)
    
    // Tv
    const [catTv, setCatTv] = useState([])
    const [tv, setTv] = useState([])
    const [tvErr, setTvErr] = useState(false)
    const [tvLoad, setTvLoad] = useState(true)
    
    //Calendar
    const [calendar, setCalendar] = useState([])
    const [calendarErr, setCalendarErr] = useState(false)
    const [calendarLoad, setCalendarLoad] = useState(true)



    useEffect(()=>{

        axios
        .get(URL_API_REVUE)
        .then((response)=>{setRevue(response.data); setRevueLoad(false)})
        .catch((err) => {console.log(err.message); setRevueErr(true); setRevueLoad(false)})

        axios
        .get(URL_API_REVUE_CAT)
        .then((response)=>{setCatRevue(response.data); setRevueLoad(false)})
        .catch((err) => {console.log(err.message); setRevueErr(true); setRevueLoad(false)})

        axios
        .get(URL_API_TV)
        .then((response)=>{setTv(response.data); setTvLoad(false)})
        .catch((err) => {console.log(err.message); setTvErr(true); setTvLoad(false)})

        axios
        .get(URL_API_TV_CAT)
        .then((response)=>{setCatTv(response.data); setTvLoad(false)})
        .catch((err) => {console.log(err.message); setTvErr(true); setTvLoad(false)})

        axios
        .get(URL_API_CALENDAR)
        .then((response)=>{setCalendar(response.data); setCalendarLoad(false)})
        .catch((err) => {console.log(err.message); setCalendarErr(true); setCalendarLoad(false)})

    },[])



    let update = {
        
        // revue
        revue: revue,
        setRevue: setRevue,
        catRevue: catRevue,
        setCatRevue: setCatRevue,
        revueErr: revueErr,
        setRevueErr: setRevueErr,
        revueLoad: revueLoad,
        setRevueLoad: setRevueLoad,

        // tv
        catTv: catTv,
        setCatTv: setCatTv,
        tv: tv,
        setTv: setTv,
        tvErr: tvErr,
        setTvErr: setTvErr,
        tvLoad: tvLoad,
        setTvLoad: setTvLoad,

        //calendar
        calendar: calendar,
        setCalendar: setCalendar,
        calendarErr: calendarErr,
        setCalendarErr: setCalendarErr,
        calendarLoad: calendarLoad,
        setCalendarLoad: setCalendarLoad,
    }

    return(
        <AppContexte.Provider value = {update}>
            {children}
        </AppContexte.Provider>
    )
}