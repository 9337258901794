import React from 'react'
import Service from './Service'
import ihs from '../../assets/img/LOGO_CLIENTS/ihs-holding-ltd-deal-logo-listing.webp'
// import soticam from '../../assets/img/LOGO_CLIENTS/soticam.png'
// import huawei from '../../assets/img/LOGO_CLIENTS/logo-Huawei.webp'
import cud from '../../assets/img/LOGO_CLIENTS/logo_cud_tranparent.png'
// import mtn from '../../assets/img/LOGO_CLIENTS/Mtn-logo-svg.svg.webp'
// import brasserie from '../../assets/img/LOGO_CLIENTS/télécharger.webp'
// import mahoption from '../../assets/img/LOGO_CLIENTS/LOGO MAHOPTION.webp'
// import accent from '../../assets/img/LOGO_CLIENTS/ACCENT.webp'
import pak from '../../assets/img/LOGO_CLIENTS/LOGO PAK.webp'
import camtrack from '../../assets/img/LOGO_CLIENTS/camtrack.png'
import dkt from '../../assets/img/LOGO_CLIENTS/dkt.png'
import saltiel from '../../assets/img/LOGO_CLIENTS/saltiel.png'
import enko from '../../assets/img/LOGO_CLIENTS/enko.jpeg'
import mintransport from '../../assets/img/LOGO_CLIENTS/mintrans.jpeg'
import besafe from '../../assets/img/LOGO_CLIENTS/besafe.png'
import bollore from '../../assets/img/LOGO_CLIENTS/bollore.png'
import cuk from '../../assets/img/LOGO_CLIENTS/VILLE DE KRIBI.webp'
import CustomerCard from './CustomerCard'
import Carousel from 'react-multi-carousel'


const Agence = () => {
    const responsive = {
        superLargeDesktop:{
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop:{
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet:{
            breakpoint: { max: 1024, min: 767 },
            items: 3
        },
        mobilet:{
            breakpoint: { max: 767, min: 615 },
            items: 2
        },
        mobiles:{
            breakpoint: { max: 615, min: 0 },
            items: 1
        },
    }


    const customers = [
        {img:ihs, nom:'IHS', site:'https://www.ihstowers.com/cm-en'},
        {img:cud, nom:'CUD', site:'https://douala.cm/',},
        // {img:'', nom:'', site:'', facebook:'', instagram:'', linkedin:''},
        {img:mintransport, nom:'ministère des transports', site:'https://mintransports.cm/'},
        {img:besafe, nom:'besafe', site:'http://besafe.cm/', facebook:'https://www.facebook.com/besafe237'},
        {img:enko, nom:'enko capital', site:'https://enkocapital.com/', linkedin:'https://www.linkedin.com/company/enko-capital/'},
        {img:saltiel, nom:'saltiel - kmg galerie', site:'https://galeriesaltiel.com/', instagram:'https://instagram.com/galeriesaltielkmg?igshid=NjIwNzIyMDk2Mg=='},
        {img:bollore, nom:'bolloré', site:'https://www.bollore.com/fr/'},
        {img:dkt, nom:'dkt international', site:'https://www.dktinternational.org/', instagram:'https://instagram.com/dkt.international?igshid=NjIwNzIyMDk2Mg==', linkedin:'https://www.linkedin.com/company/dkt-international/'},
        {img:camtrack, nom:'camtrack', site:'https://camtrack.net/', facebook:'https://www.facebook.com/camtracksas', linkedin:'https://www.linkedin.com/company/camtrack/'},
        {img:pak, nom:'PORT AUTONOME DE KRIBI', site:'https://www.pak.cm/', facebook:'https://www.facebook.com/PortOfKribi', linkedin:'https://www.linkedin.com/company/kribiport/'},
        {img:cuk, nom:'CUK', linkedin:'https://www.linkedin.com/company/communaut%C3%A9-urbaine-de-kribi/', facebook:'https://www.facebook.com/villedekribi', instagram:'https://instagram.com/communaute_urbaine_de_kribi'},
    ]

    const services =[
        {icon: "fa fa-bullhorn", title: "marketing et communication", text:"Nous proposons des services de marketing et communication complets pour booster la visibilité de votre entreprise et accroître votre chiffre d'affaires. Mahoption vous aide à créer une image de marque forte et distinctive."},
        {icon: "fa fa-video", title: "production", text:"Que ce soit pour présenter votre entreprise, réaliser un reportage institutionnel, un spot publicitaire, vidéo évènementielle, capter une conférence, partager vos valeurs ou encore faire témoigner vos clients, nos réalisations sont performantes et adaptées à votre marque."},
        {icon: "fa fa-comments", title: "consulting", text:"Nous vous offrons des solutions sur mesure pour optimiser vos processus, augmenter votre efficacité opérationnelle et stimuler votre croissance. Avec notre expertise stratégique et notre capacité à identifier les opportunités d'amélioration, nous sommes votre partenaire de confiance pour atteindre vos objectifs commerciaux."},
        {icon: "fa fa-rectangle-ad", title: "régie publicitaire", text:"Mahoption dispose d’un département spécialisé dans la régie publicitaire : Conseil en stratégie média, Définition du plan média, Production des contenus éditoriaux, Mise en place des opérations promotionnelles, Négociation et gestion des contrats publicitaires, Suivi analytique et rapport de performance des campagnes publicitaires."},
        {icon: "fa fa-icons", title: "digital et graphisme", text:"Mahoption dispose d’une équipe dynamique et créative capable de : Concevoir des sites web, Créer des applications mobiles , Gérer vos réseaux sociaux, Créer des chartes graphiques, Concevoir et implémenter des supports de communication et impression."},
        {icon: "fa fa-paper-plane", title: "location de matériel", text:"Mahoption dispose d'un département spécialisé dans la location de matériel audiovisuel pour tous types de tournages et divertissements : Drones, Caméras, Hologramme, Optiques, Photobooth, Éclairage, Audio."},
    ]

    return (
        <>
            <div id='services' className='pt-5 mt-5'>
                <h1 className='pb-4 text-uppercase text-center gradient-title'>nos Services</h1>
                <div className='row justify-content-center'>
                    {
                        services.map((elt,index)=>(
                            <Service icon={elt.icon} id={index} title={elt.title} text1={elt.text} key={index}/>
                        ))
                    }
                </div>
            </div>

            <div id='customers' className="py-5 mt-5">
                <h1 className='pb-4 text-uppercase text-center gradient-title'>ils nous font confiance</h1>
                <Carousel responsive={responsive} showDots={true} autoPlay={true} autoPlaySpeed={4000} infinite={true} className='bg-transparent pe-1' >
                {
                    customers.map((elt,index)=>(
                        <CustomerCard img={elt.img} width={'w-100 mx-1'} nom={elt.nom} site={elt.site} facebook={elt.facebook} instagram={elt.instagram} linkedin={elt.linkedin} key={index} />
                    ))
                }
                </Carousel>

            </div>
        </>
    )
}

export default Agence