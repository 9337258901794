import React, { useContext } from 'react'
import RevueCard from '../components/ui/RevueCard'
import { AppContexte } from '../context/context'
import { HashLoader } from 'react-spinners'
import axios from 'axios'
import { URL_API, URL_API_REVUE, URL_API_REVUE_CAT } from '../constant/url'
import { Accordion } from 'react-bootstrap'

const Revue = () => {

    const { catRevue, setCatRevue, revue, setRevue, revueErr, setRevueErr, revueLoad, setRevueLoad } = useContext(AppContexte)


    
    const reload = async() =>{
        setRevueLoad(true)
        axios
        .get(URL_API_REVUE)
        .then((response)=>{setRevue(response.data); setRevueLoad(false); setRevueErr(false)})
        .catch((err) => {setRevueLoad(false); setRevueErr(true); console.log(err.message);})

        axios
        .get(URL_API_REVUE_CAT)
        .then((response)=>{setCatRevue(response.data); setRevueLoad(false); setRevueErr(false)})
        .catch((err) => {setRevueLoad(false); setRevueErr(true); console.log(err.message);})
    }

    const reading = {
        display : "flex",
        margin : "0 auto"
    }

    const convertDate = (date) =>{
        const year = date.substring(0,4)
        const month = date.substring(5,7)
        const day = date.substring(8,10)
        let monthString

        switch (month) {
            case "01":
                monthString = "jan";
                break;
            case "02":
                monthString = "fév";
                break;
            case "03":
                monthString = "mar";
                break;
            case "04":
                monthString = "avr";
                break;
            case "05":
                monthString = "mai";
                break;
            case "06":
                monthString = "jun";
                break;
            case "07":
                monthString = "jul";
                break;
            case "08":
                monthString = "aoû";
                break;
            case "09":
                monthString = "sep";
                break;
            case "10":
                monthString = "oct";
                break;
            case "11":
                monthString = "nov";
                break;
            case "12":
                monthString = "déc";
                break;
            default:
                break;
        }
        return({day: day, month: monthString, year: year})
    }


    return (
        <section id='revue' className='mt-5 pt-5'>
            <h1 className='text-center text-uppercase fw-bold'>Revivez nos <span className='aer-color'>moments </span> </h1>
            <div className='container my-5'>

                <h2 id='villageEventify' className='mt-5 mb-3'>{''}</h2>
                <div className='row'>
                    {
                        revueLoad?
                        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                            <div className='text-center'>
                                <HashLoader color='#fbb03b' size={60} cssOverride={reading} aria-label='Loading spinner' data-testid='loader' className='text-center ps-3'/>
                                <p className='mt-4'>Veuillez patienter...</p>
                            </div>
                        </div>
                        :
                        revueErr?
                            <div className='w-100 h-100 d-flex justify-content-center align-items-center mb-5'>
                                <div className='text-center'>
                                    <i className='ri-error-warning-fill ri-5x text-danger'></i>
                                    <p className=''>Une erreur c'est produite. <br/> Veuillez vérifier votre connexion internet et réessayer ! </p>
                                    <button onClick={reload} className='btn bg-transparent border rounded-circle'><i className=' ri-loop-right-line px-1 ri-3x'></i></button>
                                </div>
                            </div>
                        :
                        catRevue && revue?
                        <Accordion defaultActiveKey={catRevue[catRevue.length-1].id }>
                            {
                                catRevue.map((elt,index)=>{
                                    return(
                                        <Accordion.Item eventKey={elt.id} key={index} >
                                            <Accordion.Header> {elt.title} </Accordion.Header>
                                            <Accordion.Body>
                                            {
                                                <div className='row'>
                                                    {
                                                        revue.map((revue,index) =>(
                                                            revue.categorie_id === elt.id?
                                                                <RevueCard title={revue.title} description={revue.description} link={revue.link} date={convertDate(revue.date).day + " " + convertDate(revue.date).month + " " +convertDate(revue.date).year } img={URL_API + revue.image} categorie={revue.categorie_id} key={index} />
                                                            :
                                                                null
                                                        )).reverse()
                                                    }
                                                </div>
                                            }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                }).reverse()
                            }
                        </Accordion>
                        :
                        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                            <div className='text-center'>
                                <HashLoader color='#fbb03b' size={60} cssOverride={reading} aria-label='Loading spinner' data-testid='loader' className='text-center ps-3'/>
                                <p className='mt-4'>Veuillez patienter...</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>

    )
}

export default Revue