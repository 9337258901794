/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import Modal from 'react-responsive-modal'
import { ClipLoader } from 'react-spinners'
import SubmitModal from './SubmitModal';
import Marketing from './devis/Marketing';
import Production from './devis/Production';
import Consulting from './devis/Consulting';
import Regie from './devis/Regie';
import Digital from './devis/Digital';
import Location from './devis/Location';



const Service = (props) => {

    const [open, setOpen] = useState(false)
    const [id, setId] = useState(null)
    return (
        <div className='col-md-6 z-2 col-lg-4 align-items-stretch mb-5 reveal'>
            <div className='shadow rounded-3 bg-service bg-white'>
                <div className='icon'>
                    <i className={props.icon+' aer-color'} ></i>
                </div>
                <h4 className='text-uppercase aer-color pt-3 ps-4'> {props.title} </h4>
                <h5 className='text-capitalize aer-color pt-3 ps-4'> {props.title1} </h5>
                <p className='px-4 text-start lead fs-6 pb-4'> {props.text1} </p>
                {/* <h5 className='text-capitalize aer-color ps-4'> {props.title2} </h5> */}
                <p className='px-4 text-start lead fs-6 pb-4'> {props.text2} </p>
                <button onClick={()=>{setOpen(true);setId(props.id)}} className='mx-3 mt-3 mb-3 bottom btn rounded-3 shadow border-0 border-white btn-service text-black'>{'Demander un devis'}<i className='fa fa-arrow-right ps-2'></i> </button>
            </div>





            <Modal open={open} center onClose={()=>setOpen(false)} showCloseIcon={true} classNames={{ overlayAnimationIn: 'customEnterOverlayAnimation',   overlayAnimationOut: 'customLeaveOverlayAnimation',modalAnimationIn: 'customEnterModalAnimation',modalAnimationOut: 'customLeaveModalAnimation',}}animationDuration={800} >
                <div className='container-md bg-white w-100 position-relative'>
                    {
                        id === 0?<Marketing/>:
                        id === 1?<Production/>:
                        id === 2?<Consulting/>:
                        id === 3?<Regie/>:
                        id === 4?<Digital/>:
                        id === 5?<Location/>:null
                    }
                </div>
            </Modal>
        </div> 
    )
}



const reading = {
    display : "flex",
    margin : "0 auto"
}

// Devis 1

const W11 = () =>{

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [tel, setTel] = useState("")
    const [type, setType] = useState("")
    const [lieu, setLieu] = useState("")
    const [place, setPlace] = useState("")
    const [message, setMessage] = useState("")
    
    const [read, setRead] = useState(false)
    const [ok, setOk] = useState(false)
    const [err, setErr] = useState(false)
    const {register,formState: { errors },handleSubmit} = useForm();

    const form = useRef();
    
    const sendEmail = async(e) =>{
        if (window.Email) {
            setRead(true)
            window.Email.send({
                SecureToken: "569fda13-471b-447c-9a3a-e813c707ab2a",
                To : 'eventifycmr@gmail.com',
                From : 'pangoafricadev@gmail.com',
                Subject : "Demande de devis pour un événement",
                Body :`<br/> Nom: ${name},<br/> Email: ${email},<br/> Numéro de téléphone: ${tel},<br/> Type d'évenement: ${type},<br/> Lieu de l'évenement: ${lieu},<br/>Nombre de places: ${place},<br/> Méssage: ${message}`
            }).then(message =>{
                return(
                    console.log(message),
                    setRead(false),
                    message === 'OK' ? (setOk(true), setName(''), setEmail(''), setTel(''), setType(''), setLieu(''), setPlace(''), setMessage('')): setErr(true)
                )
            })
        }

        console.log(e);
        
    }

    // eslint-disable-next-line
    const emailPattern= /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    return(
        <>
            <h4 className='text-center'>Demander un devis pour votre événement</h4>
            <form ref={form} onSubmit={handleSubmit(sendEmail)} >
                <div className='row justify-content-start align-items-strech'>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("name", {required: true })} onChange={(e)=>(setName(e.target.value))} value={name} type='text' className='w-100' required/>
                            <span>Nom</span>
                            <i></i>
                        </div>
                        {errors.name && <small className="text-danger" role="alert">Nom requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("email", {required: true, pattern: emailPattern })} onChange={(e)=>(setEmail(e.target.value))} value={email} type='text' className='w-100' required/>
                            <span>Email</span>
                            <i></i>
                        </div>
                        {errors.email?.type === "required" && <small className="text-danger" role="alert">Email requis</small>}
                        {errors.email?.type === "pattern" && <small className="text-danger" role="alert">Syntaxe d'email incorrecte</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("tel", {required: true})} onChange={(e)=>(setTel(e.target.value))} value={tel} type='tel' className='w-100' required/>
                            <span>Téléphone</span>
                            <i></i>
                        </div>
                        {errors.tel?.type === "required" && <small className="text-danger" role="alert">Numéro de téléphone requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("type", {required: true})} onChange={(e)=>(setType(e.target.value))} value={type} type='text' className='w-100' required/>
                            <span>Type d'événement</span>
                            <i></i>
                        </div>
                        {errors.type?.type === "required" && <small className="text-danger" role="alert">Type requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("lieu", {required: true})} onChange={(e)=>(setLieu(e.target.value))} value={lieu} type='text' className='w-100' required/>
                            <span>Lieu de l'événement</span>
                            <i></i>
                        </div>
                        {errors.lieu?.type === "required" && <small className="text-danger" role="alert">Lieu requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("place", {required: true})} onChange={(e)=>(setPlace(e.target.value))} value={place} type='number' className='w-100' required/>
                            <span>Nombre de places</span>
                            <i></i>
                        </div>
                        {errors.place?.type === "required" && <small className="text-danger" role="alert">Nombre de place requis</small>}
                    </div>
                    <div className='col-12 my-3'>
                        <textarea {...register("message", {required: false })} placeholder='Plus de détail' onChange={(e)=>(setMessage(e.target.value))} value={message} className='form-control' rows={'10'}></textarea>
                    </div>
                    <div className='d-flex justify-content-center col-12 mb-3'>
                    {
                        read?<button className='btn bg-transparent border border-2 border-warning'><ClipLoader 
                        color='#fbb03b' 
                        size={30} 
                        cssOverride={reading} 
                        aria-label='Loading spinner' 
                        data-testid='loader' 
                        className='text-center'/>Patientez</button> :
                        ok? <SubmitModal open={ok} setOpen={setOk} message={"Message envoyé avec success"} class={"ri-checkbox-circle-fill text-success"} classColor={"text-success"} />:
                        err? <SubmitModal open={err} setOpen={setErr} message={"Erreur survenu lors de l'envoie"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                        <input type='submit' value={"Demander le devis"} className='form-control w-100 me-3'/>
                    }
                    <input onClick={()=>{setName('');setEmail('');setTel('');setType('');setLieu('');setPlace('');setMessage('')}} type='reset' className='form-control w-100 me-3'/>
                    </div>
                </div>
            </form>
        </>
    )
}




// Devis 2

const W21 = () => {

    const checkElt = [
        {name:"location", value:"Bureaux en coworking" },
        {name:"location", value:"Salle de réunion" },
        {name:"location", value:"Espace de cocktail de 50 personnes" },
        {name:"location", value:"Plateau TV" },
        {name:"location", value:"Studio photo" },
        {name:"location", value:"Autre" },
    ]

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [tel, setTel] = useState("")
    const [type, setType] = useState("")
    const [message, setMessage] = useState("")
    
    const [read, setRead] = useState(false)
    const [ok, setOk] = useState(false)
    const [err, setErr] = useState(false)
    const {register,formState: { errors },handleSubmit} = useForm();

    const form = useRef();
    
    const sendEmail = async(e) =>{

        if (window.Email) {
            setRead(true)
            window.Email.send({
                SecureToken: "569fda13-471b-447c-9a3a-e813c707ab2a",
                To : 'eventifycmr@gmail.com',
                From : 'pangoafricadev@gmail.com',
                Subject : "Demande de devis pour une location",
                Body :`<br/> Nom: ${name},<br/> Email: ${email},<br/> Numéro de téléphone: ${tel},<br/> Type de location: ${type},<br/> Méssage: ${message}`
            }).then(message =>{
                return(
                    console.log(message),
                    setRead(false),
                    message === 'OK' ?( setOk(true), setName(''), setEmail(''), setTel(''), setType(''), setMessage('') ): setErr(true)
                )
            })
        }
        console.log(e);
    }
    // eslint-disable-next-line
    const emailPattern= /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    return(
        <>
            <h4 className='text-center me-3'>Demander un devis pour votre location</h4>
            <form ref={form} onSubmit={handleSubmit(sendEmail)}>
                <h5 className='text-center mt-4'>Quel type de location <span className='aer-color'>?</span></h5>
                <div className='p-2 border border-1 border-warning rounded'>
                    {
                        checkElt.map((elt,index)=>(
                            <div className='py-1 d-flex fs-5' key={index}>
                                <input onClick={(e)=>setType(e.target.value)} {...register("type", {required: true })} type='radio' className='form-check-input form-check-inline' name={elt.name} id={index} value={elt.value} />
                                <label className="" htmlFor={index} > {elt.value} </label>
                            </div>
                            ))
                        }
                        {errors.type && <small className="text-danger" role="alert">Type requis</small>}
                </div>
                <h5 className='text-center mt-4'>Quelques informations personnelles</h5>
                <div className='row justify-content-start align-items-strech'>
                    <div className='col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("name", {required: true })} onChange={(e)=>(setName(e.target.value))} value={name} type='text' className='w-100' required/>
                            <span>Nom</span>
                            <i></i>
                        </div>
                        {errors.name && <small className="text-danger" role="alert">Nom requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("email", {required: true, pattern: emailPattern })} onChange={(e)=>(setEmail(e.target.value))} value={email} type='text' className='w-100' required/>
                            <span>Email</span>
                            <i></i>
                        </div>
                        {errors.email?.type === "required" && <small className="text-danger" role="alert">Email requis</small>}
                        {errors.email?.type === "pattern" && <small className="text-danger" role="alert">Syntaxe d'email incorrecte</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("tel", {required: true })} onChange={(e)=>(setTel(e.target.value))} value={tel} type='number' className='w-100' required/>
                            <span>Téléphone</span>
                            <i></i>
                        </div>
                        {errors.tel?.type === "required" && <small className="text-danger" role="alert">Numéro de téléphone requis</small>}
                    </div>
                    <div className='col-12 my-3'>
                        <textarea {...register("message")} required={true} placeholder='Plus de détail' onChange={(e)=>(setMessage(e.target.value))} value={message} className='form-control' rows={'10'}></textarea>
                        {errors.message?.type === "required" && <small className="text-danger" role="alert">Détails requis</small>}
                    </div>
                    <div className='d-flex justify-content-center col-12 mb-3'>
                    {
                        read?<button className='btn bg-transparent border border-2 border-warning'><ClipLoader 
                        color='#fbb03b' 
                        size={30} 
                        cssOverride={reading} 
                        aria-label='Loading spinner' 
                        data-testid='loader' 
                        className='text-center'/>Patientez</button> :
                        ok? <SubmitModal open={ok} setOpen={setOk} message={"Message envoyé avec success"} class={"ri-checkbox-circle-fill text-success"} classColor={"text-success"} />:
                        err? <SubmitModal open={err} setOpen={setErr} message={"Erreur survenu lors de l'envoie"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                        <input type='submit' value={"Demander le devis"} className='form-control w-100 me-3'/>
                    }
                    <input onClick={()=>{setName('');setEmail('');setTel('');setType('');setMessage('')}} type='reset' className='form-control w-100 me-3'/>
                    </div>
                </div>
            </form>
        </>
    )
}





// Devis 3

const W31 = () => {
    
    const checkElt = [
        {name:"regie", value:"Création d'un spot publicitaire ?" },
        {name:"regie", value:"Réalisation d'une émission TV ?" },
        {name:"regie", value:"Voix off ?" },
        {name:"regie", value:"Campagne publicitaire sur ceinture digitale, Douala - Yaounde ?" },
        {name:"regie", value:"Personal branding ?" },
        {name:"regie", value:"Autre" },
    ]

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [tel, setTel] = useState("")
    const [type, setType] = useState("")
    const [message, setMessage] = useState("")
    
    const [read, setRead] = useState(false)
    const [ok, setOk] = useState(false)
    const [err, setErr] = useState(false)
    const {register,formState: { errors },handleSubmit} = useForm();

    const form = useRef();
    
    const sendEmail = async(e) =>{
        if (window.Email) {
            setRead(true)
            window.Email.send({
                SecureToken: "569fda13-471b-447c-9a3a-e813c707ab2a",
                To : 'eventifycmr@gmail.com',
                From : 'pangoafricadev@gmail.com',
                Subject : "Demande de régie publicitaire & Materiel audio-visuel",
                Body :`<br/> Nom: ${name},<br/> Email: ${email},<br/> Numéro de téléphone: ${tel},<br/> Type de location: ${type},<br/> Méssage: ${message}`
            }).then(message =>{
                return(
                    console.log(message),
                    setRead(false),
                    message === 'OK' ?( setOk(true), setName(''), setEmail(''), setTel(''), setType(''), setMessage('') ): setErr(true)
                )
            })
        }

        console.log(e);

    }
    
    // eslint-disable-next-line
    const emailPattern= /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    return(
        <>
            <h4 className='text-center me-3'> Régie publicitaire & Materiel audio-visuel </h4>
            <form ref={form} onSubmit={handleSubmit(sendEmail)}>
                <h5 className='text-center mt-4'>Quel type de demande <span className='aer-color'>?</span></h5>
                <div className='p-2 border border-1 border-warning rounded'>
                    {
                        checkElt.map((elt,index)=>(
                            <div className='py-1 d-flex fs-5' key={index}>
                                <input onClick={(e)=>setType(e.target.value)} {...register("type", {required: true })} type='radio' className='form-check-input form-check-inline' name={elt.name} id={index} value={elt.value} />
                                <label className="" htmlFor={index} > {elt.value} </label>
                            </div>
                            ))
                        }
                        {errors.type && <small className="text-danger" role="alert">Type requis</small>}
                </div>
                <h5 className='text-center mt-4'>Quelques informations personnelles</h5>
                <div className='row justify-content-start align-items-strech'>
                    <div className='col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("name", {required: true })} onChange={(e)=>(setName(e.target.value))} value={name} type='text' className='w-100' required/>
                            <span>Nom</span>
                            <i></i>
                        </div>
                        {errors.name && <small className="text-danger" role="alert">Nom requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("email", {required: true, pattern: emailPattern })} onChange={(e)=>(setEmail(e.target.value))} value={email} type='text' className='w-100' required/>
                            <span>Email</span>
                            <i></i>
                        </div>
                        {errors.email?.type === "required" && <small className="text-danger" role="alert">Email requis</small>}
                        {errors.email?.type === "pattern" && <small className="text-danger" role="alert">Syntaxe d'email incorrecte</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("tel", {required: true})} onChange={(e)=>(setTel(e.target.value))} value={tel} type='number' className='w-100' required/>
                            <span>Téléphone</span>
                            <i></i>
                        </div>
                        {errors.tel?.type === "required" && <small className="text-danger" role="alert">Numéro de téléphone requis</small>}
                    </div>
                    <div className='col-12 my-3'>
                        <textarea {...register("message", {required: true })} placeholder='Plus de détail' onChange={(e)=>(setMessage(e.target.value))} value={message} className='form-control' rows={'10'}></textarea>
                        {errors.message?.type === "required" && <small className="text-danger" role="alert">Détails requis</small>}
                    </div>
                    <div className='d-flex justify-content-center col-12 mb-3'>
                    {
                        read?<button className='btn bg-transparent border border-2 border-warning'><ClipLoader 
                        color='#fbb03b' 
                        size={30} 
                        cssOverride={reading} 
                        aria-label='Loading spinner' 
                        data-testid='loader' 
                        className='text-center'/>Patientez</button> :
                        ok? <SubmitModal open={ok} setOpen={setOk} message={"Message envoyé avec success"} class={"ri-checkbox-circle-fill text-success"} classColor={"text-success"} />:
                        err? <SubmitModal open={err} setOpen={setErr} message={"Erreur survenu lors de l'envoie"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                        <input type='submit' value={"Demander le devis"} className='form-control w-100 me-3'/>
                    }
                    <input onClick={()=>{setName('');setEmail('');setTel('');setType('');setMessage('')}} type='reset' className='form-control w-100 me-3'/>
                    </div>
                </div>
            </form>
        </>
    )
}





export default Service