import React from 'react'

const About = () => {
    
    return (
        <div id='about' className=' mt-5 mb-3'>
            <h1 className='text-center text-uppercase mb-4'><span className="gradient-title">Qui sommes nous ?</span></h1>
            <div className='mb-5'>
                <div className='shadow rounded-3 bg-service bg-white'>
                    <div className='icon w-auto h-100'>
                        <i className={'fa fa-house-flag aer-color fs-1 p-3 pe-2'} ></i>
                    </div>
                    <p className='px-3 pt-3 pb-1 text-start lead fs-6'> 
                        MAHOPTION est une agence de production & événementielle qui s'appuie d’un côté sur son agilité en tant que jeune structure et de l’autre, sur ses forces vives dont certains ont près de 20 ans d’expérience audiovisuelle dans de grands groupes internationaux et en réalisation. Mahoption c’est aussi une agence de communication, consulting, production audiovisuelle et développement web. Nous vous offrons une gamme complète de services, de la conception à la production, en passant par la post- production et la diffusion. Chez Mahoption, nous nous engageons à produire des contenus de qualité qui répondent aux normes les plus élevées
                    </p>
                </div>
            </div> 
        </div>
    )
}

export default About