import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/js/dist/dropdown.js';
import './assets/vendor/fontawesome/css/all.min.css'
import './assets/vendor/remixicon/remixicon.css'

import './components/styles/style.css'
import Accueil from './pages/Accueil';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/ui/Header';
import Social from './components/ui/Social';
import Calendar from './pages/Calendar';
import Footer from './components/ui/Footer';
import Revue from './pages/Revue';
import Tv from './pages/Tv';
import Contact from './pages/Contact';

const App = () => {

  window.addEventListener('scroll',()=>{
    var reveal = document.querySelectorAll('.reveal');

    for (var i = 0; i < reveal.length; i++) {
      var windowHeight = window.innerHeight;
      var revealTop = reveal[i].getBoundingClientRect().top;
      var revealPoint = 0;

      if (revealTop <  windowHeight - revealPoint) {
        reveal[i].classList.add('activeReveal');
      }else{
        reveal[i].classList.remove('activeReveal');
      }
      
    }
  })

  return (
    <BrowserRouter>
      <Header/>
      <Social/>
      <Routes>
        <Route path='/'>
          <Route index element={<Accueil/>} />
          <Route path='tv' element={<Tv/>} />
          <Route path='calendar' element={<Calendar/>} />
          <Route path='revue-presse' element={<Revue/>} />
          <Route path='Contact' element={<Contact/>} />
        </Route>
      </Routes>
      <Footer/>
    </BrowserRouter>
  )
}

export default App
