import React, { useEffect } from 'react'
import 'react-responsive-modal/styles.css';
import "react-multi-carousel/lib/styles.css";
import Hero from '../components/ui/Hero';
import About from '../components/ui/About';
import Agence from '../components/ui/Agence';
import { useLocation } from 'react-router-dom';
import prod1 from '../assets/img/PRODUCTIONS/WAOUW.png'
import prod2 from '../assets/img/PRODUCTIONS/EAR.png'
import prod3 from '../assets/img/PRODUCTIONS/TUTOPLAY.png'
import prod4 from '../assets/img/PRODUCTIONS/BTP.png'
import Carousel from 'react-multi-carousel';

const Accueil = () => {

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
        window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ block: "start", inline: "start" });
            }
        }, 0);
        }
    }, [pathname, hash, key]); 

    const draw = () =>{
        let paths = document.querySelectorAll('path')
        for (let i = 0; i < paths.length; i++) {
            let path = paths[i]
            let pathLength = path.getTotalLength()
            path.style.strokeDasharray = pathLength + ' ' + pathLength
            path.style.strokeDashoffset = pathLength
            path.getBoundingClientRect()
            window.addEventListener('scroll', ()=> {
                var scrollPercentage = ( document.documentElement.scrollTop + document.body.scrollTop ) / ( document.documentElement.scrollHeight - document.documentElement.clientHeight )
                var drawLength = pathLength * (scrollPercentage + 0.25)
                path.style.strokeDashoffset = pathLength - drawLength
            })
        }
    }

    window.addEventListener('scroll',() =>{draw()})

    
    const responsive = {
        superLargeDesktop:{
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop:{
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet:{
            breakpoint: { max: 1024, min: 767 },
            items: 4
        },
        mobilet:{
            breakpoint: { max: 767, min: 615 },
            items: 3
        },
        mobiles:{
            breakpoint: { max: 615, min: 0 },
            items: 2
        },
    }

    const production = [
        {img: prod2, link:'https://ear-gt.com/', alt:'Event AutoStop Rallye GT'},
        {img: prod3, link:'/contact', alt:'Tuto play'},
        {img: prod1, link:'/contact', alt:'Waouw'},
        {img: prod4, link:'/contact', alt:'BTP'},
    ]



    return (
        <div>
            <Hero/>
            <div className='container'>
                <div id='svg'>
                    <div className=' d-lg-block d-none'>
                        {/* start left */}
                        <svg className='c-site-path' viewBox="0 0 1314 1588" fill="none" preserveAspectRatio='xMidYMax meet'>
                            <path d="M1 1L327 510V733.5H555.5V843H357V1583.5H952" stroke="#5AA78D"/>
                        </svg>
                        <svg className='c-site-path' viewBox="0 0 1314 1588" fill="none" preserveAspectRatio='xMidYMax meet'>
                            <path d="M1 1L327 510V735.5H553.5V841H355V1585.5H952" stroke="#5AA78D"/>
                        </svg>
                        {/* end left */}

                        {/* start center */}
                        <svg className='c-site-path' viewBox="0 0 1314 841" fill="none" preserveAspectRatio='xMidYMax meet'>
                            <path d="M1 1L325 510V735.5H657.5V1585.5H1314" stroke="#5AA78D"/>
                        </svg>
                        <svg className='c-site-path' viewBox="0 0 1314 841" fill="none" preserveAspectRatio='xMidYMax meet'>
                            <path d="M1 1L325 510V735.5H655.5V1587.5H1314" stroke="#5AA78D"/>
                        </svg>
                        {/* end center */}

                        {/* start rigth */}
                        <svg className='c-site-path' viewBox="0 0 1314 1588" fill="none" preserveAspectRatio='xMidYMax meet'>
                            <path d="M1 1L325 510V735.5H733.5V841H954V1585.5H950" stroke="#5AA78D"/>
                        </svg>
                        <svg className='c-site-path' viewBox="0 0 1314 1588" fill="none" preserveAspectRatio='xMidYMax meet'>
                            <path d="M1 1L325 510V735.5H731.5V843H952V1583.5H940" stroke="#5AA78D"/>
                        </svg>
                        {/* end rigth */}
                    </div>

                    <div className='d-lg-none d-md-block d-none'>
                        {/* start left */}
                        <svg className='c-site-path' viewBox="0 0 947 2118" fill="none" preserveAspectRatio='xMidYMax meet'>
                            <path d="M0.5 1L184 377V659.5H409.5V752.5H184V2117H947" stroke="#5AA78D"/>
                        </svg>
                        <svg className='c-site-path' viewBox="0 0 947 2118" fill="none" preserveAspectRatio='xMidYMax meet'>
                            <path d="M0.5 1L184 377V661.5H407.5V750.5H182V2119H947" stroke="#5AA78D"/>
                        </svg>
                        {/* end left */}


                        {/* start rigth */}
                        <svg className='c-site-path' viewBox="0 0 947 2118" fill="none" preserveAspectRatio='xMidYMax meet'>
                            <path d="M0.5 1L184 377V659.5H556.5V752.5H762V2117H947" stroke="#5AA78D"/>
                        </svg>
                        <svg className='c-site-path' viewBox="0 0 947 2120" fill="none" preserveAspectRatio='xMidYMax meet'>
                            <path d="M0.5 1L184 377V659.5H554.5V754.5H760V2119H947" stroke="#5AA78D"/>
                        </svg>
                        {/* end rigth */}
                    </div>

                </div>
                
                <About/>
                <Agence/>

            </div>
            {/* Productions */}
            <div id='productions'>
                <div className='container'>
                    <div className='py-5 mt-4'>
                        <h1 className='pb-4 text-uppercase text-center text-white'>nos productions</h1>
                        <Carousel responsive={responsive} showDots={false} autoPlay={false} infinite={false} className='bg-transparent pe-1' >
                        {
                            production.map((elt,index)=>(
                                <div key={index} className='mx-2'>
                                    <a href={elt.link} target='_blank' rel='noreferrer'>
                                        <img alt={elt.alt} src={elt.img} className='img-fluid productions'/>
                                    </a>
                                </div>
                            ))
                        }
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accueil