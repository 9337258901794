import React from 'react'
import logo from '../../assets/img/LOGO MAHOPTION.webp'
import { NavLink } from 'react-router-dom'

const Footer = () => {

    const createSquare = () =>{
        const section = document.querySelector('#footerDots')
        const dot = document.createElement('span')
        // const colors = ['#39FF14','#FF5E00','#FFF01F','#DFFF00','#EA00FF','#0FF0FC','#BC13FE',]

        var size = Math.random() * 10
        dot.style.width = size + 'px'
        dot.style.height = size + 'px'
        dot.style.borderRadius = '0'

        dot.style.top = 10 + Math.random() * section.clientHeight + 'px'
        dot.style.left = -10 + Math.random() * section.clientWidth + 'px' 

        // const bg = colors[Math.floor(Math.random() * colors.length)]

        // dot.style.background = bg

        section.appendChild(dot)
        setTimeout(()=>{
            dot.remove()
        },5000)
    }

    setInterval(createSquare, 200)

    return (
        <footer id='footerDots' className=' bg-footer pt-5 pb-3 position-relative'>
            <div className='container'>
                <div id='cta' className="mb-5">
                    <div className='px-md-5 py-md-4 p-2 bg-black'>
                        <div className='row align-items-center justify-content-between'>
                            <div className="text-white col-md-auto col-12">
                                <h2 className='text-uppercase mb-1'>Prêt pour un prochain projet ?</h2>
                                <h5 className='text-uppercase'>Commençons !!!</h5>
                            </div>
                            <div className="col-md-auto col-12 mt-md-0 mt-3">
                                <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/contact'} className="btn px-3 py-2 bg-trnasparent text-white fw-bold text-uppercase rounded-0 sub-link border-1 border-warning">Contactez nous</NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row justify-content-between align-items-start pt-4'>
                    <div className=' mb-5 col-lg-4 me-3'>
                        <div className='d-block'>
                            <img alt="logo Mahoption" src={logo} className='img-fluid mb-3' />
                            <span className='text-white'>"Votre satisfaction n'est pas une option !"</span>
                        </div>
                    </div>
                    <div className='col-lg row justify-content-end'>
                        <div className='mb-5 col-lg'>
                            <h3 className='text-uppercase mb-3 secondary-color'>Liens utiles</h3>
                            <ul className='list-unstyled'>
                                <li className='mb-2 d-flex fw-medium fs-6 align-items-center'>
                                    <i className="ri-arrow-right-double-fill ri-1x secondary-color me-3"></i>
                                    <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/#about'} className='text-decoration-none text-white sub-link'>Qui Sommes nous ? </NavLink>
                                </li>
                                <li className='mb-2 d-flex fw-medium fs-6 align-items-center'>
                                    <i className="ri-arrow-right-double-fill ri-1x secondary-color me-3"></i>
                                    <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/#services'} className='text-decoration-none text-white sub-link'>Nos services</NavLink>
                                </li>
                                <li className='mb-2 d-flex fw-medium fs-6 align-items-center'>
                                    <i className="ri-arrow-right-double-fill ri-1x secondary-color me-3"></i>
                                    <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/calendar'} className='text-decoration-none text-white sub-link'>Calendrier</NavLink>
                                </li>
                                <li className='mb-2 d-flex fw-medium fs-6 align-items-center'>
                                    <i className="ri-arrow-right-double-fill ri-1x secondary-color me-3"></i>
                                    <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/revue-presse'} className='text-decoration-none text-white sub-link'>Revue de presse</NavLink>
                                </li>
                                <li className='mb-2 d-flex fw-medium fs-6 align-items-center'>
                                    <i className="ri-arrow-right-double-fill ri-1x secondary-color me-3"></i>
                                    <a href={'https://ear-gt.com'} target="_BLANK" rel="noreferrer" className='text-decoration-none text-white sub-link'>Event Auto'Stop Rallye GT</a>
                                </li>
                            </ul>
                        </div>
                        <div className='mb-5 col-lg'>
                            <h3 className='text-uppercase mb-3 secondary-color'>Nos Réseaux</h3>
                            <ul className='list-unstyled'>
                                <li className='mb-2 d-flex fw-medium fs-6 align-items-center'>
                                    <i className="fs-5 fa-brands fa-instagram secondary-color p-0 me-3"></i>
                                    <a target={'_blank'} rel='noreferrer' href='https://instagram.com/mahoption_' className='text-decoration-none text-white sub-link'>mahoption_</a>
                                </li>
                                <li className='mb-2 d-flex fw-medium fs-6 align-items-center'>
                                    <i className="fs-5 fa-brands fa-linkedin-in fa-2xs secondary-color p-0 me-3"></i>
                                    <a target={'_blank'} rel='noreferrer' href='https://www.linkedin.com/company/mahoption/' className='text-decoration-none text-white sub-link'>MAHOPTION</a>
                                </li>
                                <li className='mb-2 d-flex fw-medium fs-6 align-items-center'>
                                    <i className="fs-5 fa-brands fa-facebook-f fa-2xs secondary-color p-0 me-3 pe-1"></i>
                                    <a target={'_blank'} rel='noreferrer' href='https://www.facebook.com/missmahop?mibextid=LQQJ4d' className='text-decoration-none text-white sub-link'>Mahoption</a>
                                </li>
                                <li className='mb-2 d-flex fw-medium fs-6 align-items-center'>
                                    <i className="fs-5 fa-brands fa-youtube fa-2xs secondary-color p-0 pe-2"></i>
                                    <a target={'_blank'} rel='noreferrer' href='https://youtube.com/@MAHOPTION' className='text-decoration-none text-white sub-link'>MAHOPTION</a>
                                </li>
                            </ul>
                        </div>
                        <div className='mb-5 col-lg'>
                            <h3 className='text-uppercase mb-3 secondary-color'>Nos Contacts</h3>
                            <ul className=' list-unstyled'>
                                <li className='mb-2 d-flex fw-medium fs-6 align-items-center'>
                                    <i className="ri-mail-send-fill fa-envelope fs-5 secondary-color me-3 pe-1"></i>
                                    <a href='mailto:contact@mahoption.com' className='text-decoration-none text-white'>contact@mahoption.com</a>
                                </li>
                                <li className='mb-2 d-flex fw-medium fs-6 align-items-center'>
                                    <i className="ri-phone-fill fs-5 secondary-color me-3"></i>
                                    <div className='d-flex flex-column'>
                                        <a href='tel:+237651298633' className='text-decoration-none text-white'>+237 651 29 86 33</a>
                                        <a href='tel:+237692869935' className='text-decoration-none text-white'>+237 692 86 99 35</a>
                                    </div>
                                </li>
                                <li className='mb-2 d-flex fw-medium fs-6 align-items-center'>
                                    <i className="ri-map-pin-fill fs-5 secondary-color me-4"></i>
                                    <a target="_blank" rel='noreferrer' href='https://www.google.com/maps/place/Mahoption+LTD/@4.017523,9.704428,16z/data=!4m6!3m5!1s0x10611368da0024af:0x5a065140bc0e64!8m2!3d4.0148241!4d9.7050078!16s%2Fg%2F11l5srxl_g?hl=fr&entry=ttu' className='text-decoration-none text-white'>Hôtel de l'air Bonapriso, après Energy Club, Douala - Cameroun</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div id='copyrigths' className='text-white'>
                    <hr/>
                    <small> Copyright &copy; 2023 Mahoption. Tous Droits Reservés </small>
                </div>
            </div>
        </footer>
    )
}

export default Footer