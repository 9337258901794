import React, { useState } from 'react'
import vids from '../../assets/video/SHOW_REEL_MAHOPTION.webm'

const Hero = () => {
    const style ={
        marginTop: "-2rem",
        zIndex: "10"
    }

    const [mute, setMute] = useState(true)

    return (
        <>
            <div className="">
                <video autoPlay muted={mute} loop className='hero-vid z-2'>
                    <source src={vids} type='video/mp4' />
                </video>
                <div className="d-flex justify-content-end me-3">
                    <i type="button" style={style} className = {"text-end fa-xl" + (mute ? ' fa fa-volume-xmark' : ' fa fa-volume-high')} onClick={()=> setMute(!mute)} ></i>
                </div>
            </div>
        </>
    )
}

export default Hero