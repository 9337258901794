//API URL
export const URL_API = 'https://admin-mahoption-server.eventify.cm/'


// API REVUE
export const URL_API_REVUE = 'https://admin-mahoption-server.eventify.cm/api/revue/'
export const URL_API_REVUE_CAT = 'https://admin-mahoption-server.eventify.cm/api/catRevue/'


// API TV
export const URL_API_TV = 'https://admin-mahoption-server.eventify.cm/api/tv/'
export const URL_API_TV_CAT = 'https://admin-mahoption-server.eventify.cm/api/catTv/'


// API CALENDAR
export const URL_API_CALENDAR = 'https://admin-mahoption-server.eventify.cm/api/calendar/'