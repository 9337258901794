import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import SubmitModal from '../SubmitModal'
import { ClipLoader } from 'react-spinners'



const Production = () => {

    const checkElt = [
        {name:"location", value:"Reportage institutionnel" },
        {name:"location", value:"Spot publicitaire" },
        {name:"location", value:"Vidéo évènementielle" },
        {name:"location", value:"Capter une conférence" },
        {name:"location", value:"Faire témoigner vos clients" },
        {name:"location", value:"Autre" },
    ]


    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [tel, setTel] = useState("")
    const [society, setSociety] = useState("")
    const [secteur, setSecteur] = useState("")
    const [message, setMessage] = useState("")
    const [type, setType] = useState("")
    
    const [read, setRead] = useState(false)
    const [ok, setOk] = useState(false)
    const [err, setErr] = useState(false)
    const {register,formState: { errors },handleSubmit} = useForm();

    const form = useRef();
    
    const sendEmail = async(e) =>{
        if (window.Email) {
            setRead(true)
            window.Email.send({
                SecureToken: "569fda13-471b-447c-9a3a-e813c707ab2a",
                To : 'contact@mahoption.com',
                From : 'pangoafricadev@gmail.com',
                Subject : "mahoption.com: Demande de devis pour un production",
                Body: `<div style="width: 90%; background: #ededed; margin: 10px auto">
                        <h1 style="padding:10px; background: #f4c709; margin-bottom: 8px">DEMANDE DE DEVIS PRODUCTION</h1>
                        <div style="padding: 12px; margin: 10px auto">
                            <table style="width: 100%; border: 1px solid #9f9f9f">
                                <thead style="background: #000; color: #fff; padding: .5rem">
                                    <tr>
                                        <th style="padding: .5rem">INFOS</th>
                                        <th>CONTENU</th>
                                    </tr>
                                </thead>
                                <tbody style="background: #fff; margin:8px">
                                    <tr style="border-bottom: 1px solid #9f9f9f">
                                        <th style="padding: .5rem">NOMS</th>
                                        <td>${name}</td>
                                    </tr>
                                    <tr style="border-bottom: 1px solid #9f9f9f">
                                        <th style="padding: .5rem">NUMERO DE TELEPHONE</th>
                                        <td>${tel}</td>
                                    </tr>
                                    <tr style="border-bottom: 1px solid #9f9f9f">
                                        <th style="padding: .5rem">NOM DE SOCIETE</th>
                                        <td>${society}</td>
                                    </tr>
                                    <tr style="border-bottom: 1px solid #9f9f9f">
                                        <th style="padding: .5rem">SECTEUR D'ACTIVITE</th>
                                        <td>${secteur}</td>
                                    </tr>
                                    <tr style="border-bottom: 1px solid #9f9f9f">
                                        <th style="padding: .5rem">ADRESSE MAIL</th>
                                        <td>${email}</td>
                                    </tr>
                                    <tr style="border-bottom: 1px solid #9f9f9f">
                                        <th style="padding: .5rem">TYPE DE PROJET</th>
                                        <td>${type}</td>
                                    </tr>
                                    <tr style="border-bottom: 1px solid #9f9f9f">
                                        <th style="padding: .5rem">MESSAGE</th>
                                        <td>${message}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>`,
            }).then(message =>{
                return(
                    console.log(message),
                    setRead(false),
                    message === 'OK' ? (setOk(true), setName(''), setEmail(''), setTel(''), setSociety(''), setSecteur(''), setMessage('')): setErr(true)
                )
            })
        }
        
    }

    // eslint-disable-next-line
    const emailPattern= /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    return(
        <>
            <h4 className='text-center'>Demander un devis pour Production</h4>
            <form ref={form} onSubmit={handleSubmit(sendEmail)} >
                <div className='row justify-content-start align-items-strech'>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("name", {required: true })} onChange={(e)=>(setName(e.target.value))} value={name} type='text' className='w-100' required/>
                            <span>Nom</span>
                            <i></i>
                        </div>
                        {errors.name && <small className="text-danger" role="alert">Nom requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("tel", {required: true})} onChange={(e)=>(setTel(e.target.value))} value={tel} type='tel' className='w-100' required/>
                            <span>Téléphone</span>
                            <i></i>
                        </div>
                        {errors.tel?.type === "required" && <small className="text-danger" role="alert">Numéro de téléphone requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("society", {required: true})} onChange={(e)=>(setSociety(e.target.value))} value={society} type='text' className='w-100' required/>
                            <span>Nom de la société</span>
                            <i></i>
                        </div>
                        {errors.society?.society === "required" && <small className="text-danger" role="alert">Nom societé requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("secteur", {required: true})} onChange={(e)=>(setSecteur(e.target.value))} value={secteur} type='text' className='w-100' required/>
                            <span>Secteur d'activité</span>
                            <i></i>
                        </div>
                        {errors.secteur?.type === "required" && <small className="text-danger" role="alert">Secteur d'activité requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register("email", {required: true, pattern: emailPattern })} onChange={(e)=>(setEmail(e.target.value))} value={email} type='text' className='w-100' required/>
                            <span>Email</span>
                            <i></i>
                        </div>
                        {errors.email?.type === "required" && <small className="text-danger" role="alert">Email requis</small>}
                        {errors.email?.type === "pattern" && <small className="text-danger" role="alert">Syntaxe d'email incorrecte</small>}
                    </div>
                    <div className='col-12 mt-3'>
                        <span className='aer-color'> Type de projet </span>
                        <div className='p-2 border border-1 border-warning rounded'>
                        {
                            checkElt.map((elt,index)=>(
                                <div className='py-1 d-flex fs-5' key={index}>
                                    <input onClick={(e)=>setType(e.target.value)} {...register("type", {required: true })} type='radio' className='form-check-input form-check-inline' name={elt.name} id={index} value={elt.value} />
                                    <label className="" htmlFor={index} > {elt.value} </label>
                                </div>
                            ))
                        }
                        {errors.type && <small className="text-danger" role="alert">Type requis</small>}
                        </div>
                    </div>
                    <div className='col-12 my-3'>
                        <label htmlFor="detail" className='aer-color ps-2'>Détails</label>
                        <textarea id='detail' {...register("message", {required: false })} placeholder='Soyez le plus précis possible, pour que notre réponse soit la plus proche de vos attentes' onChange={(e)=>(setMessage(e.target.value))} value={message} className='form-control' rows={'10'}></textarea>
                    </div>
                    <div className='d-flex justify-content-center col-12 mb-3'>
                    {
                        read?<button className='btn bg-transparent border border-2 border-warning'><ClipLoader 
                        color='#fbb03b' 
                        size={30} 
                        cssOverride={{display : "flex",margin : "0 auto"}} 
                        aria-label='Loading spinner' 
                        data-testid='loader' 
                        className='text-center'/>Patientez</button> :
                        ok? <SubmitModal open={ok} setOpen={setOk} message={"Message envoyé avec success"} class={"ri-checkbox-circle-fill text-success"} classColor={"text-success"} />:
                        err? <SubmitModal open={err} setOpen={setErr} message={"Erreur survenu lors de l'envoie"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                        <input type='submit' value={"Demander le devis"} className='form-control w-100 me-3'/>
                    }
                    <input onClick={()=>{setName('');setEmail('');setTel('');setSociety('');setSecteur('');setMessage('')}} type='reset' className='form-control w-100 me-3'/>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Production