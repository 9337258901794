import React, { useContext } from 'react'
import axios from 'axios'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import { AppContexte } from '../context/context'
import { URL_API_TV, URL_API_TV_CAT } from '../constant/url'
import { HashLoader } from 'react-spinners'

const Tv = () => {

    const { catTv, setCatTv, tv, setTv, tvErr, setTvErr, tvLoad, setTvLoad } = useContext(AppContexte)

    const reload = async() =>{
        setTvLoad(true)
        axios
        .get(URL_API_TV)
        .then((response)=>{setTv(response.data); setTvLoad(false); setTvErr(false)})
        .catch((err) => {setTvLoad(false); setTvErr(true); console.log(err.message);})

        axios
        .get(URL_API_TV_CAT)
        .then((response)=>{setCatTv(response.data); setTvLoad(false); setTvErr(false)})
        .catch((err) => {setTvLoad(false); setTvErr(true); console.log(err.message);})
    }

    const reading = {
        display : "flex",
        margin : "0 auto"
    }

    return (
        <>
            <section id='bg-tv' className='py-5 pb-2 pb-sm-5'>
                <div className='container py-5 pb-2 pb-lg-5 d-flex justify-content-start'>
                    <h1 className='text-center fw-bold pb-0'>VIDEOS <span className='aer-color'>  </span> </h1>
                </div>
            </section>
            <div className=' container mt-5'>
                {
                    tvLoad?
                        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                            <div className='text-center'>
                                <HashLoader color='#fbb03b' size={60} cssOverride={reading} aria-label='Loading spinner' data-testid='loader' className='text-center ps-3'/>
                                <p className='mt-4'>Veuillez patienter...</p>
                            </div>
                        </div>
                    :
                    tvErr?
                        <div className='w-100 h-100 d-flex justify-content-center align-items-center mb-5'>
                            <div className='text-center'>
                                <i className='ri-error-warning-fill ri-5x text-danger'></i>
                                <p className=''>Une erreur c'est produite. <br/> Veuillez vérifier votre connexion internet et réessayer ! </p>
                                <button onClick={reload} className='btn bg-transparent border rounded-circle'><i className=' ri-loop-right-line px-1 ri-3x'></i></button>
                            </div>
                        </div>
                    :
                    catTv && tv?
                        <Tab.Container className = 'text-center mx-auto' id="left-tabs-example" defaultActiveKey={catTv[catTv.length-1].id}>
                            <Row className = 'text-center'>
                                <Col sm={12} md={3} lg={2}>
                                    {/* Different onglets */}
                                        <Nav variant="pills" className="bmc-portfolio mb-4 justify-content-center">
                                            {
                                                catTv.map((elt,index) =>{
                                                    return(
                                                        <Nav.Item key={index} className='d-md-block' >
                                                            <Nav.Link className='fw-bold text-black' eventKey={elt.id}>{elt.categorie}</Nav.Link>
                                                        </Nav.Item>
                                                    )
                                                })
                                            }
                                        </Nav>
                                </Col>
                                <Col sm={12} md={9} lg={10} className = 'mx-auto'>
                                    <Tab.Content className = 'mx-auto'>
                                        {/* Videos en reponse aux onglets */}
                                        {
                                            catTv.map((cat, index) =>{
                                                return(
                                                    <Tab.Pane eventKey={cat.id} key={index} >
                                                        <h1 className='text-warning text-uppercase'>{cat.title}</h1>
                                                        <h2 className='text-black fs-6 text-center'>{cat.description}</h2>
                                                        <div className='row mt-3 text-center'>
                                                            {
                                                                tv.map((tv, index)=>(
                                                                    tv.categorie_id === cat.id ?
                                                                        <div className='col-md-4 col-12 mb-3' key={index}>
                                                                            <iframe className=' img-fluid border border-success p-1' title='Video disponible sur la chaine youtube Mahoption' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' src={tv.link}></iframe>
                                                                        </div>
                                                                    :
                                                                        null
                                                                ))
                                                            }
                                                        </div>
                                                    </Tab.Pane>
                                                )
                                            })
                                        }
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    :
                    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                        <div className='text-center'>
                            <HashLoader color='#fbb03b' size={60} cssOverride={reading} aria-label='Loading spinner' data-testid='loader' className='text-center ps-3'/>
                            <p className='mt-4'>Veuillez patienter...</p>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default Tv
