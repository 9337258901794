import React from 'react'

const RevueCard = (props) => {
    return (
        <div className='col-md-4 col-12 mb-4'>
            <div className='rounded-3 mb-3 card shadow-sm'>
                <img alt='article' src={props.img}  className='card-img-top rounded-3' />
                <div className=' card-body'>
                    <h5 className='aer-color text-center text-uppercase'> {props.title} </h5>
                    <p className=''> {props.description} </p>
                    <small className='fs-6 fw-thin text-uppercase'> {props.date} </small>
                    <br></br>
                </div>
                <div className=' card-footer bg-white border-0 mx-auto mb-2'>
                    <a rel='noreferrer' target='_blank' href={props.link} className='text-success text-decoration-none border border-1 border-warning rounded-pill py-1 px-3 fs-5'> Lire plus... </a>
                </div>
            </div>
        </div>
    )
}

export default RevueCard