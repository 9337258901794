import React, { useRef, useState } from 'react'
import { useForm } from "react-hook-form"
import { ClipLoader } from 'react-spinners'
import SubmitModal from '../components/ui/SubmitModal'


const Contact = () => {

    
    const contact = [
        {icon: "fa fa-phone", title:"telephone", text1:" +237 692 86 99 35", text2: "+237 674 17 01 93"},
        {icon: "fa fa-envelope", title:"Email", text1:"contact@mahoption.com",},
        {icon: "fa fa-home", title:"adresse", text1:"Hôtel de l'air Bonapriso, après Energy Club, Douala - Cameroun",},
    ]
    
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [object, setObject] = useState("")
    const [message, setMessage] = useState("")
    
    const [read, setRead] = useState(false)
    const [ok, setOk] = useState(false)
    const [err, setErr] = useState(false)

    const {register,formState: { errors },handleSubmit} = useForm();

    const form = useRef();

    const sendEmail = async(e) =>{

        if (window.Email) {
            setRead(true)
            window.Email.send({
                SecureToken: "569fda13-471b-447c-9a3a-e813c707ab2a",
                To : 'contact@mahoption.com',
                From : 'pangoafricadev@gmail.com',
                Subject : "mahoption.com: Devenir Partenaire",
                Body :` Nom: ${name},<br/> Email: ${email},<br/> Objet: ${object}  <br/> Message: ${message}.`
            }).then((message) =>{
                return(
                    console.log(message),
                    setRead(false),
                    message === 'OK' ?( setOk(true), setName(''), setEmail(''), setObject(''), setMessage('') ): setErr(true)
                )
            })
        }
        
    
        console.log(e);
    }

    // eslint-disable-next-line
    const emailPattern= /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

    const reading = {
        display : "flex",
        margin : "0 auto"
    }
    
    return (
        <>
            <section id='bg-contact' className='pt-md-5 pb-md-5 pt-5 pb-sm-3 pb-0'>
                <div className='container pt-3 mb-0 pb-0 pt-md-5 pb-md-2 mt-md-5 text-start my-auto'>
                    <h1 className='fw-bold'>ORGANISONS VOTRE EVENEMENT</h1>
                    <h3 className='text-center d-none d-md-block'>Conseil - Création - Production - Implémentation</h3>
                </div>
            </section>
            <div className='container my-5'>
                <div className='row justify-content-center'>
                    <div className='col-12 row justify-content-between align-items-center flex-wrap-reverse mt-5'>
                        <div className='col-lg-6 d-lg-block d-none col-12'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15920.108727189887!2d9.7050078!3d4.0148241!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10611368da0024af%3A0x5a065140bc0e64!2sMahoption%20LTD!5e0!3m2!1sfr!2scm!4v1698410238394!5m2!1sfr!2scm"height="450" allowFullScreen="1" className='w-100 border-1 border border-warning' loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='carte de localisation de Mahoption LTD'></iframe>
                        </div>
                        <div className='col-lg-6 mb-5 mb-lg-0 col-12'>
                            <h1 className='mb-3 text-center'>Envoyer un <span className='aer-color'>message ?</span> </h1>
                            <form ref={form} onSubmit={handleSubmit(sendEmail)}>
                                <div className='row justify-content-start align-items-strech'>
                                    <div className='col-md-6 col-12 mb-2'>
                                        <div className='inputBox'>
                                            <input {...register("name", {required: true })} onChange={(e)=>(setName(e.target.value))} value={name} type='text' className='w-100' required/>
                                            <span>Nom</span>
                                            <i></i>
                                        </div>
                                        {errors.name && <small className="text-danger" role="alert">Nom requis</small>}
                                    </div>
                                    <div className='col-md-6 col-12 mb-2'>
                                        <div className='inputBox'>
                                            <input {...register("email", {required: true, pattern: emailPattern })} onChange={(e)=>(setEmail(e.target.value))} value={email} type='text' className='w-100' required/>
                                            <span>Email</span>
                                            <i></i>
                                        </div>
                                        {errors.email?.type === "required" && <small className="text-danger" role="alert">Email requis</small>}
                                        {errors.email?.type === "pattern" && <small className="text-danger" role="alert">Syntaxe d'email incorrecte</small>}
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <div className='inputBox'>
                                            <input {...register("object", {required: true })} onChange={(e)=>(setObject(e.target.value))} value={object} type='text' className='w-100' required/>
                                            <span>Objet</span>
                                            <i></i>
                                        </div>
                                        {errors.object?.type === "required" && <small className="text-danger" role="alert">Objet requis</small>}
                                    </div>
                                    <div className='col-12 my-3'>
                                        <textarea {...register("message", {required: true })} placeholder='Message' onChange={(e)=>(setMessage(e.target.value))} value={message} className='form-control' rows={'10'}></textarea>
                                        {errors.message?.type === "required" && <small className="text-danger" role="alert">Message requis</small>}
                                    </div>
                                    <div className='d-flex justify-content-center col-12 mb-3'>
                                        {
                                            read?<button className='btn bg-transparent border border-2 border-warning'><ClipLoader 
                                            color='#fbb03b' 
                                            size={30} 
                                            cssOverride={reading} 
                                            aria-label='Loading spinner' 
                                            data-testid='loader' 
                                            className='text-center'/>Patientez</button> :
                                            ok? <SubmitModal open={ok} setOpen={setOk} message={"Message envoyé avec success"} class={"ri-checkbox-circle-fill text-success"} classColor={"text-success"} />:
                                            err? <SubmitModal open={err} setOpen={setErr} message={"Erreur survenu lors de l'envoie"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                                            <input type='submit' className='form-control w-100 me-3'/>
                                        }
                                        <input onClick={()=>{setName('');setEmail('');setObject('');setMessage('')}} type='reset' className='form-control w-100 me-3'/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-12 row mt-0 mt-md-5 justify-content-center'>
                        {
                            contact.map((elt, index) =>(
                                <div className='col-md-4 my-3 col-12' key={index}>
                                    <div className='adress-card bg-white shadow rounded border border-2 border-white p-2'>
                                        <div className='adress-icon bg-white shadow-sm border border-2 py-3 rounded-circle'>
                                            <i className={ elt.icon + " fa-xl mx-3"}></i>
                                        </div>
                                        <div className='text-center mt-4 mb-2'>
                                            <h2 className='text-uppercase'>{elt.title}</h2>
                                            <span>{elt.text1}</span><br/>
                                            <span>{elt.text2}</span><br/>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='col-lg-6 mt-5 d-lg-none d-block col-12'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15920.108727189887!2d9.7050078!3d4.0148241!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10611368da0024af%3A0x5a065140bc0e64!2sMahoption%20LTD!5e0!3m2!1sfr!2scm!4v1698410238394!5m2!1sfr!2scm"height="450" allowFullScreen="1" className='w-100 border-1 border border-warning' loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='carte de localisation de Mahoption LTP'></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
