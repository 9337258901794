import React from 'react'

const CustomerCard = (props) => {
    return (
        <div className={' col-md-4 col-sm-6 col-9 col-lg-3 mb-5 '+props.width}>
            <div className='rounded-3 customer-card position-relative d-block shadow text-center mx-2'>
                <div className='position-relative d-flex align-items-center justify-content-center pb-5 logo-customer'>
                    <img src={props.img} alt={props.nom} className='rounded-top img-fluid position-relative d-block'/>
                </div>
                <div id='clt' className='px-2 d-flex flex-column rounded-bottom position-absolute bg-white rounded shadow'>  
                    <h6 className='text-uppercase text-center pt-2 aer-color'>{props.nom}</h6>
                    <small className='text-center'> {props.description} </small>
                    <div className='d-flex justify-content-around py-2'>
                        {
                            props.site?(
                                <a href={props.site} className='link-customer' target='_blank' rel='noreferrer' > <i className='fa fa-globe'></i> </a>
                            ):null
                        }
                        {
                            props.facebook?(
                                <a href={props.facebook} className='link-customer' target='_blank' rel='noreferrer' > <i className='fa-brands fa-facebook'></i> </a>
                            ):null
                        }
                        {
                            props.linkedin?(
                                <a href={props.linkedin} className='link-customer' target='_blank' rel='noreferrer' > <i className='fa-brands fa-linkedin'></i> </a>
                            ):null
                        }
                        {
                            props.instagram?(
                                <a href={props.instagram} className='link-customer' target='_blank' rel='noreferrer' > <i className='fa-brands fa-instagram'></i> </a>
                            ):null
                        }
                    </div>
                </div>
            </div>
            <div className='py-4'></div>
        </div>
    )
}

export default CustomerCard