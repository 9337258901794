import React, { useContext, useState } from 'react'
import Modal from 'react-responsive-modal'
import { AppContexte } from '../context/context'
import { HashLoader } from 'react-spinners'
import axios from 'axios'
import { URL_API, URL_API_CALENDAR } from '../constant/url'

const Calendar = () => {

    const { calendar, setCalendar, calendarErr, setCalendarErr, calendarLoad, setCalendarLoad } = useContext(AppContexte)


    const [open, setOpen] = useState(false)
    const [img, setImg] = useState()
    const [event, setEvent] = useState('')
    const [day, setDay] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [place, setPlace] = useState('')
    
    const convertDate = (date) =>{
        const year = date.substring(0,4)
        const month = date.substring(5,7)
        const day = date.substring(8,10)
        let monthString

        switch (month) {
            case "01":
                monthString = "jan";
                break;
            case "02":
                monthString = "fév";
                break;
            case "03":
                monthString = "mar";
                break;
            case "04":
                monthString = "avr";
                break;
            case "05":
                monthString = "mai";
                break;
            case "06":
                monthString = "jun";
                break;
            case "07":
                monthString = "jul";
                break;
            case "08":
                monthString = "aoû";
                break;
            case "09":
                monthString = "sep";
                break;
            case "10":
                monthString = "oct";
                break;
            case "11":
                monthString = "nov";
                break;
            case "12":
                monthString = "déc";
                break;
            default:
                break;
        }
        return({day: day, month: monthString, year: year})
    }

    const EventCard = (props) => {
        return (
            <div role='button' className='mb-2' onClick={()=>{setImg(URL_API+props.image); setEvent(props.title); setDay(convertDate(props.date).day); setMonth(convertDate(props.date).month); setYear(convertDate(props.date).year); setPlace(props.lieu); setOpen(true)}}>
                <div className='position-relative p-2 mb-3 row justify-content-center'>
                    <div className='bg-calendar row mb-3 align-items-center overflow-hidden pe-0'>
                        <div className="date col-auto row justify-content-center align-items-center align-self-stretch py-md-5 py-2">
                            <div className='date-s'>{props.month}</div>
                            <div className='date-l'>{props.day}</div>
                            <div className='date-s'>{props.year}</div>
                        </div>
                        <div className='col-md col-12 w-100 p-3 ps-4'>
                            <div className='w-100 h-100 row align-items-center'>
                                <div className='d-flex flex-column justify-content-center align-items-start w-100 flex-shrink-1 overflow-hidden pe-0'>
                                    <h2 className='text-warning fw-medium fs-4 text-uppercase mb-1'>{props.title}</h2>
                                    <span className='fs-6 mb-3'>{props.description}</span>
                                    <span className="place p-1 px-2 rounded-pill d-flex flex-row align-items-center">
                                        <i className="fa-solid fa-location-dot me-2"></i>
                                        <small className='text-capitalize'>{props.lieu}</small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    const reload = async() =>{
        setCalendarLoad(true)
        axios
        .get(URL_API_CALENDAR)
        .then((response)=>{setCalendar(response.data); setCalendarLoad(false); setCalendarErr(false)})
        .catch((err) => {setCalendarLoad(false); setCalendarErr(true); console.log(err.message);})
    }

    const reading = {
        display : "flex",
        margin : "0 auto"
    }

    return (
        <>
            <section id='bg-clients' className='pt-5'>
                <div className='container py-5 d-flex justify-content-start'>
                    <h1 className='text-center fw-bold py-md-5 py-lg-5 pb-0'>PROCHAINS <span className='aer-color'>EVENEMENTS...</span> </h1>
                </div>
            </section>

            <section id='calendar' className='container mt-5'>
                {
                    calendarLoad?
                    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                        <div className='text-center'>
                            <HashLoader color='#fbb03b' size={60} cssOverride={reading} aria-label='Loading spinner' data-testid='loader' className='text-center ps-3'/>
                            <p className='mt-4'>Veuillez patienter...</p>
                        </div>
                    </div>
                    :
                    calendarErr?
                        <div className='w-100 h-100 d-flex justify-content-center align-items-center mb-5'>
                            <div className='text-center'>
                                <i className='ri-error-warning-fill ri-5x text-danger'></i>
                                <p className=''>Une erreur c'est produite. <br/> Veuillez vérifier votre connexion internet et réessayer ! </p>
                                <button onClick={reload} className='btn bg-transparent border rounded-circle'><i className=' ri-loop-right-line px-1 ri-3x'></i></button>
                            </div>
                        </div>
                    :
                    calendar?.length ?
                    calendar.map((event,index) =>(
                        <EventCard title={event.title} description={event.description} lieu={event.lieu} date={event.date} year={convertDate(event.date).year} month={convertDate(event.date).month} day={convertDate(event.date).day} id={event.id} image={event.image} key={index} />
                    ))
                    :
                    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                        <div className='text-center'>
                            <HashLoader color='#fbb03b' size={60} cssOverride={reading} aria-label='Loading spinner' data-testid='loader' className='text-center ps-3'/>
                            <p className='mt-4'>Veuillez patienter...</p>
                        </div>
                    </div>
                }
            </section>
            
            <Modal open={open} center onClose={()=>{setOpen(false); setImg(); setEvent(''); setDay(''); setMonth(''); setYear(''); setPlace('');}} showCloseIcon={false} classNames={{ overlayAnimationIn: 'customEnterOverlayAnimation',   overlayAnimationOut: 'customLeaveOverlayAnimation',modalAnimationIn: 'customEnterModalAnimation',modalAnimationOut: 'customLeaveModalAnimation',}} animationDuration={800} >
                <div className='event'>
                    <img alt={event} src={img} className='img-fluid'/>
                    <div className="p-3">
                        <h2 className='text-uppercase fw-bold aer-color'> {event} </h2>
                        <span className="bg-secondary bg-opacity-25 p-1 px-2 rounded-pill mb-3">
                            <i className="fa-solid fa-calendar-day me-2"></i>
                            <small className='text-capitalize'>{day + " " + month + " " + year}</small>
                        </span>
                        <div className='my-3'></div>
                        <span className="bg-secondary bg-opacity-25 p-1 px-2 rounded-pill mt-3">
                            <i className="fa-solid fa-location-dot me-2"></i>
                            <small className='text-capitalize'>{place}</small>
                        </span>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Calendar